import React from "react";
import "./Section4.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
function Section4() {
  return (
    <div id="Roadmap" className="section4">
      <img className="roadmap" src="./assets/IMG_4812.png" alt=""></img>
      <div className="roadmap_list">
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div>
            <div className="roadmap_img">
              <img src="./assets/Frame.png" alt="" />
            </div>
            <div style={{ position: "relative" }} className="roadmap_text">
              <img src="./assets/Rectangle 50.png" alt="" />

              <div className="box_text">
                <div>Just Got Rejected</div>

                <ul>
                  <li>
                    Launch mint for all @Rejected Members, claiming their
                    Genesis Reject to access the afterverse
                  </li>
                  <li>
                    Any remaining Genesis Dead Rejects Supply is moved to a
                    public mint
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div>
            <div className="roadmap_text">
              <img src="./assets/Rectangle 50.png" alt="" />

              <div className="box_text">
                <div>Life after rejection</div>

                <ul>
                  <li>
                    All Rejects enter the afterverse (private holder lounge)
                  </li>
                  <li>
                    The RejecteDAO will be set up for all rejects, who will be a
                    part of the DAO exclusive decision-making process to make
                    sure holders have the ability to help facilitate and lead
                    the project.
                  </li>
                </ul>
              </div>
            </div>
            <div className="roadmap_img">
              <img src="./assets/2 (2).png" alt="" />
            </div>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div>
            <div className="roadmap_img">
              <img src="./assets/3 (2).png" alt="" />
            </div>
            <div className="roadmap_text">
              <img src="./assets/bigR1.png" alt="" />
              <div className="box_text">
                <div>Hope of Survival</div>

                <ul>
                  <li>
                    The rejects hope to survive in the afterverse by hunting for
                    $DED. $DED is launched and spread out across the afterverse.
                    Rejects now have a chance at survival Rejects can be staked
                    to start accumulating $DED. Each rejects earns 7 $DED per
                    day, $90,000 USD (From Mint Revenue), Along with 90% of all
                    Genesis Reject royalties (per month) will be put towards our
                    Liquidity Pool to allow rejects to trade $DED easily on
                    exchanges
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div>
            <div className="roadmap_text">
              <img src="./assets/bigR2.png" alt="" />
              <div className="box_text">
                <div>The Lifeblood of the Afterverse; $DED</div>

                <ul>
                  <li>
                    In this phase, rejects can upgrade themselves by minting
                    undeads. Undeads will act as a multiplier and offer higher
                    staking rewards to holders in this phase. All unminted
                    supply will be burnt.
                  </li>
                  <li>
                    Later In this phase, rejects come across female rejects and
                    are enamored by their power and beauty. Female rejects can
                    be minted by rejects using $DED or $SOL (With preference to
                    reject holders). These female rejects are rare and with
                    special abilities, so every reject wants to mint one. Female
                    rejects give undeads preference. Holders with undeads will
                    have priority in minting females. Females also act as
                    multipliers and get even higher staking rewards compared to
                    undeads.
                  </li>
                </ul>
              </div>
            </div>
            <div className="roadmap_img">
              <img src="./assets/4.png" alt="" />
            </div>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div>
            <div className="roadmap_img">
              <img src="./assets/5 (2).png" alt="" />
            </div>
            <div className="roadmap_text">
              <img src="./assets/Rectangle 50.png" alt="" />
              <div className="box_text">
                <div>The End…Of The Beginning</div>

                <ul>
                  <li>
                    We plan to keep building out greater utilities for our
                    ecosystem of holders. As our ecosystem expands we plan to
                    add more benefits to users who hold all three, rejects,
                    dead, undead , and females. There will also be surprise
                    elements to these mints. We will continue building until we
                    break Solana.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
}

export default Section4;
