import React from "react";
import Section3 from "../Section3/Section3";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Section2.css";
function Section2() {
  return (
    <div className="section2_body">
      <div className="section2_body_socials">
        <a href="https://discord.gg/deadrejects">
          <div>
            <img src="./assets/Frame 53.png" alt="" />
          </div>
        </a>
        <a href="https://twitter.com/DeadRejcts">
          <div>
            <img src="./assets/Frame 707.png" alt="" />
          </div>
        </a>
      </div>
      <div id="About" className="section2_body_title">
        <div className="section2_body_title_img">
          <AnimationOnScroll animateIn="animate__rotateInUpLeft">
            <img src="./assets/why reject2.png" alt="" />
          </AnimationOnScroll>
        </div>
        <div className="section2_body_title_text">
          <div>WHY REJECTs?</div>
          <p>
            They started out as normal dudes, listened to music, did their own
            shit, but you know how life goes, the depression, anxiety, gets a
            bit much. For these guys it was too much to handle. They needed an
            escape, a place without all this real world crap, where all of them
            could do whatever the hell they wanted, without feeling the way they
            did before. That’s where DEDs came in, their last hope, made for
            rejects, by rejects. One hit of this and they were in a world of
            their own, “the afterverse” they called it, and obviously none of
            them wanted out. The real world became their prison, the one place
            they began to fear and there was only one thing preventing them from
            going back. They only have one option now, hunt for the next DEDs
            hit, and it’s really goddamn rare. Time to hunt.
          </p>
        </div>
      </div>
      {/* <Section3 /> */}
    </div>
  );
}

export default Section2;
