import React from "react";
import "./Section8.css";
function Section8() {
  return (
    <div className="section8">
      <img className="become" src="./assets/Become.png" alt="" />
      <div className="socials_footer">
        <a href="https://discord.gg/deadrejects">
          <img className="disc" src="./assets/disc.svg" alt="" />
        </a>
        <a href="https://twitter.com/DeadRejcts">
          <img className="twitter" src="./assets/twitter.svg" alt="" />
        </a>
      </div>
      <p>Designed by DEAD REJEcts | Copyrights @ 2022</p>
    </div>
  );
}

export default Section8;
