import React from "react";
import "./Section5.css";
function Section5() {
  return (
    <div className="section5">
      <img className="tokenomics" src="./assets/IMG_4836 1.png" alt="" />
      <div className="section5_body_title">
        <div className="section5_body_title_img">
          <img src="./assets/Coin.gif" alt="" />
        </div>
        <div className="section5_body_title_text">
          <div>The LifeBlood: $DED?</div>
          <p>
            The Rejects are addicted to feeding on $DED, they hunt for it in the
            afterverse. Without DEDs rejects begin to fade away. Our token,
            $DED, is the lifeblood of our ecosystem, we have a bunch of planned
            inter-utilities that will be exclusive to it. Additionally, $DED
            acts as a source of passive income for all holders, therefore
            attempting to maximize incentives to hold. Each Genesis Reject can
            be minted for 1.75 SOL. There will only ever be 3939 Dead Rejects.
            All rejects survive and grow stronger by feeding on DEDs. They
            wander in the Solana afterverse hunting for $DEDs, without it, they
            begin to fade away.
          </p>
        </div>
        <div></div>
      </div>
      <div>
        <a href="https://medium.com/@deadrejectsnft/the-rejectonomics-4ddd3c30300f">
          <img className="learn" src="./assets/Learn.png" alt="" />
        </a>
      </div>
    </div>
  );
}

export default Section5;
