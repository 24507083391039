import React from "react";
import "./Section7.css";
import "./Section7.scss";
function Section7() {
  return (
    <div className="section7">
      <img className="team" src="./assets/meet.png" alt="" />
      <div id="Team" className="teams_list">
        <div className="team_list_individual">
          <div className="container">
            <div className="item">
              <img className="person" src="./assets/Kartik.png" alt="" />
            </div>
          </div>
          <div className="team_name ">Kartik Mehrotra</div>
          <div className="team_postn">Project Lead</div>
          <a href="https://www.linkedin.com/in/kartikmehrotra9">
            <img className="linkedin" src="./assets/linkedin.svg" alt="" />
          </a>
        </div>

        <div className="team_list_individual">
          <div className="container">
            <div className="item">
              <img className="person" src="./assets/Group 612.png" alt="" />
            </div>
          </div>

          <div className="team_name">Gary Conway</div>
          <div className="team_postn">Financial Advisor</div>
          <a href="https://www.linkedin.com/in/gary-conway-03646a19">
            <img className="linkedin" src="./assets/linkedin.svg" alt="" />
          </a>
        </div>

        <div className="team_list_individual">
          <div className="container">
            <div className="item">
              <img className="person" src="./assets/Group 613.png" alt="" />
            </div>
          </div>

          <div className="team_name">Juan Pablo Chen</div>
          <div className="team_postn">Art Director</div>
          <a href="https://www.linkedin.com/in/juanpablochen">
            <img className="linkedin" src="./assets/linkedin.svg" alt="" />
          </a>
        </div>
      </div>
      <div className="teams_list">
        <div className="team_list_individual">
          <div className="container">
            <div className="item">
              <img className="person" src="./assets/Group 619.png" alt="" />
            </div>
          </div>

          <div className="team_name">Ratan Kapoor</div>
          <div className="team_postn">Head of Strategy</div>
          <a href="https://www.linkedin.com/in/ratan-kapoor-828ab655">
            <img className="linkedin" src="./assets/linkedin.svg" alt="" />
          </a>
        </div>

        <div className="team_list_individual">
          <div className="container">
            <div className="item">
              <img className="person" src="./assets/Brahm.png" alt="" />
            </div>
          </div>

          <div className="team_name">Brahm Maira</div>
          <div className="team_postn">Marketing Manager</div>
          <a href="https://www.linkedin.com/in/brahm-maira-a35a4314">
            <img className="linkedin" src="./assets/linkedin.svg" alt="" />
          </a>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Section7;
