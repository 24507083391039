import React from "react";
import "./Section6.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AnimationOnScroll } from "react-animation-on-scroll";
function Section6() {
  return (
    <div className="section6">
      <img id="Faq" className="faqs" src="./assets/faqs.png" alt="" />
      <div>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Supply? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">
                The total supply is 3939, out of this 2500 members will be
                rejected, and the remaining 1439 supply will be available to the
                public.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Mint price? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">
                The mint price is set at 1.75 Solana
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Launch?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">
                We have set our launch date as the 16th April 2022.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Secondary Market?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">
                We will be listing on Magic Eden as our secondary market place
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>$DED? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">
                DEDs are the core of our entire ecosystem, in-depth information
                is available in the rejectonomics.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AnimationOnScroll>
      </div>
    </div>
  );
}

export default Section6;
