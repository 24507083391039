import React from "react";
import "./Section1.css";
function Section1() {
  return (
    <div>
      <img className="section1_bkg" src="./assets/Group 1.png" alt="" />
    </div>
  );
}

export default Section1;
