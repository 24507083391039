import React from "react";

import "./Section3.css";
import Slider from "react-slick";
import { AnimationOnScroll } from "react-animation-on-scroll";
function Section3() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <div id="Collection" className="section3">
      <img className="showcase" src="./assets/showcase.png" alt="" />
      <div>
        {/* <AnimationOnScroll animateIn="animate__rubberBand"> */}
        <Slider {...settings}>
          <div>
            <img src="./assets/Group 620.png" alt=""></img>
          </div>
          <div>
            <img src="./assets/Group 604.png" alt=""></img>
          </div>
          {/* <div>
              <img src="./assets/why.png" alt=""></img>
            </div>
            <div>
              <img src="./assets/why2.png" alt=""></img>
            </div> */}
          {/* <div>
              <img src="./assets/showcase/7.png" alt=""></img>
            </div>
            <div>
              <img src="./assets/showcase/8.png" alt=""></img>
            </div>
            <div>
              <img src="./assets/showcase/5.png" alt=""></img>
            </div>
            <div>
              <img src="./assets/showcase/6.png" alt=""></img>
            </div> */}
        </Slider>
        {/* </AnimationOnScroll> */}
      </div>
    </div>
  );
}

export default Section3;
